<template>
  <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 2xl:grid-cols-3">
    <router-link
      v-for="mod in getModules"
      :key="mod.name"
      :to="mod.path || ''"
      class="p-3 group transition bg-white rounded-lg shadow-lg overflow-hidden"
    >
      <div class="h-full flex flex-col justify-between p-4">
        <div>
          <div
            class="h-10 w-10 px-4 py-3 flex justify-center items-center bg-secondary rounded-full text-xl transition text-white"
          >
            <a-icon :type="mod.icon" class="mr-auto"></a-icon>
          </div>

          <div class="mt-4 text-left">
            <h2 class="text-lg font-bold transition text-primary">
              {{ mod.title }}
            </h2>
            <p class="transition text-primary">
              {{ mod.description }}
            </p>
          </div>
        </div>
        <span class="block mt-5 text-secondary font-bold text-right"
          >Ver más ></span
        >
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('session', ['getModules']),
  },
}
</script>

<style></style>
